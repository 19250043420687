@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: "poppins", system-ui, sans-serif;
      @apply text-slate-600;
    }
  }

